import React from "react";
import classes from "./ArticleDetailsInviteReviewersSection.module.css";
import InviteReviewerContainer from "./InviteReviewerContainer";
import ArticleDetailsSideBarActionButton from "./ArticleDetailsSideBarActionButton";
import ArticleDetailsSideBarTitle from "./ArticleDetailsSideBarTitle";

function ArticleDetailsInviteReviewersSection({
  similarProfiles,
  openInviteModal,
  articleDetails,
}) {
  return (
    <section className={classes.inviteReviewersSection}>
      <ArticleDetailsSideBarTitle title="invite to review" />
      {similarProfiles.map((profile, key) => (
        <InviteReviewerContainer
          key={key}
          name={`${profile.full_name}`}
          description={profile.description}
          profileImage={profile.profile_picture}
          username={profile.username}
          currentPosition={profile.job_title}
          articleDetails={articleDetails}
        />
      ))}
      <div className="d-flex justify-content-center">
        <ArticleDetailsSideBarActionButton
          btnText="invite via email/username"
          onClick={openInviteModal}
        />
      </div>
    </section>
  );
}

export default ArticleDetailsInviteReviewersSection;
