import React, { useEffect, useState } from "react";
import classes from "./ArticleSuggestion.module.css";
import DiffComponent from "../components/DiffComponent";
import ArticleSuggestionDescription from "../components/ArticleSuggestionDescription";
import ArticleSuggestionsAcceptDeclineButtonSet from "../components/ArticleSuggestionsAcceptDeclineButtonSet";
import { useLoaderData, defer } from "react-router-dom";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import displayToastErrors from "../utils/displayToastErrors";

export default function ArticleSuggestion() {
  const loader = useLoaderData();
  const [originalTitle, setOriginalTitle] = useState("");
  const [suggestedTitle, setSuggestedTitle] = useState("");
  const [originalContent, setOriginalContent] = useState("");
  const [suggestedContent, setSuggestedContent] = useState("");
  const [articleData, setArticleData] = useState(null);

  // set Docuement title
  useDocumentTitle("Puplier | Article Suggestions");

  // triggers on initial load
  useEffect(() => {
    const { comparisonViewLoader } = loader;

    comparisonViewLoader
      .then(({ response }) => {
        let articleData = response.data.data;

        setOriginalTitle(articleData.original_article.title);
        setSuggestedTitle(articleData.suggested_article.title);
        setOriginalContent(articleData.original_article.content);
        setSuggestedContent(articleData.suggested_article.content);

        setArticleData(articleData);
      })
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data.message,
          "Faild to load article data!"
        );
      });
  }, [loader]);

  return (
    <div className="container customContainer">
      <div className="row justify-content-center">
        <div className="col-xxl-11 col-12">
          <div className={classes.editArticleContainer}>
            {articleData && (
              <ArticleSuggestionDescription article={articleData} />
            )}
            <DiffComponent
              type="Title"
              title="Suggestions for the Title"
              prevText={originalTitle}
              newText={suggestedTitle}
            />
            <DiffComponent
              type="Content"
              title="Suggestions for the Content"
              prevText={originalContent}
              newText={suggestedContent}
            />
            <ArticleSuggestionsAcceptDeclineButtonSet />
          </div>
        </div>
      </div>
    </div>
  );
}

export const articleSuggestionsLoader =
  (sendRequest) =>
  async ({ params: { review_request_id: reviewRequestId } }) => {
    const comparisonViewLoader = sendRequest(
      "get",
      `/articles/review-requests/sent/${reviewRequestId}/compare/`
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });

    return defer({ comparisonViewLoader });
  };

export const articleSuggestionsAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const user = JSON.parse(localStorage.getItem("user"));
    const { articleReviewReqId, articleStatus, authorAction } =
      Object.fromEntries(formData);

    const data = {};

    if (authorAction === "accept") {
      data.article_next_status = articleStatus;
    }

    return sendRequest(
      "put",
      `/articles/review-requests/sent/${articleReviewReqId}/${authorAction}/`,
      data
    );
  };
