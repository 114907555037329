import useHttp from "./useHttp";

export default function useCreateReviewRequest({
  trackProgressOfRequest = false,
}) {
  const { sendRequest, sendRequestAndTrackProgress, isLoading, actionData } =
    useHttp();

  const createReviewRequest = async (reviewers, articleId) => {
    const data = { reviewers: reviewers.split(",") };

    if (trackProgressOfRequest) {
      return sendRequestAndTrackProgress(
        "post",
        `/articles/${articleId}/invite/`,
        data
      );
    }

    return sendRequest("post", `/articles/${articleId}/invite/`, data);
  };

  return { createReviewRequest, isLoading, actionData };
}
