import { useEffect, useState } from "react";
import useHttp from "./useHttp";
import displayToastErrors from "../utils/displayToastErrors";

export default function useFetchAllSimilarProfiles() {
  const [similarProfiles, setSimilarProfiles] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { sendRequest } = useHttp();

  const getSimilarProfiles = () => {
    const method = "get";
    const url = "/user/similar-users/";
    const params = {};

    const resolved = (response) => {
      setSuccess(true);
      setSimilarProfiles(response.data.data);
    };

    const rejected = (error) => {
      setError(true);
      displayToastErrors(
        error?.response?.data?.message || "Faild to load similar profiles!"
      );
    };

    sendRequest(method, url, params, resolved, rejected);
  };

  useEffect(() => {
    getSimilarProfiles();
  }, []);

  return { similarProfiles, success, error };
}
