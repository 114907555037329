import React, { useEffect, useState } from "react";
import DashboardTabTitle from "../components/dashboard/DashboardTabTitle";
import PendingEditRequestsList from "../components/dashboard/edit-requests-tab/PendingEditRequestsList";
import titleIcon from "./images/shared-with-me-title-icon.png";
import { Await, defer, useLoaderData } from "react-router-dom";
import SubTitle from "../components/dashboard/edit-requests-tab/SubTitle";
import classes from "./SharedWithMe.module.css";
import editRequestsTitleIcon from "../components/images/pending-edit-requests-title-icon.png";
import reviewsInProgessTitleIcon from "../components/images/reviews-in-progress-title-icon.png";
import SharedWithMeArticlesList from "../components/SharedWithMeArticlesList";
import RequestProgressBar from "../components/RequestProgressBar";
import DashboardMessageContainer from "../components/DashboardMessageContainer";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import displayToastErrors from "../utils/displayToastErrors";

export default function SharedWithMe() {
  const data = useLoaderData();
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const [isLoadingSharedArticles, setIsLoadingSharedArticles] = useState(true);
  const [isLoadingPendingEditRequests, setIsLoadingPendingEditRequests] =
    useState(true);

  // set Docuement title
  useDocumentTitle("Puplier | Shared With Me");

  useEffect(() => {
    data.inProgressEditRequests
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data.message,
          "Error loading in-progress edit requests!"
        );
      })
      .finally(() => {
        setIsLoadingSharedArticles(false);
      });

    data.pendingEditRequests
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data.message,
          "Error loading pending edit requests!"
        );
      })
      .finally(() => {
        setIsLoadingPendingEditRequests(false);
      });

    return () => {
      // Need to cancle this API request on cleanup.
      // But with strictMode,this API request is being aborted in very first render
      // So during the second render, the request is already aborted and it throws an error.
      // It can be fixed by removing the strictMode from index.js
      // But as it's not a good practice to remove strictMode and also it's not a heavy API request,
      // for now, I'm not aborting this request.
    };
  }, [data]);

  useEffect(() => {
    if (!isLoadingSharedArticles && !isLoadingPendingEditRequests) {
      setCompleteProgressBar(true);
    }
  }, [isLoadingSharedArticles, isLoadingPendingEditRequests]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
      />
      <DashboardTabTitle icon={titleIcon} tabName="review requests" />
      {/* Pending edit requests section starts here */}
      <SubTitle
        icon={editRequestsTitleIcon}
        title="pending review requests"
        overrideStyle={classes.subTitle}
      />
      <React.Suspense>
        <Await
          resolve={data.pendingEditRequests}
          errorElement={<DashboardMessageContainer status="error" />}
        >
          {({ response }) => (
            <PendingEditRequestsList editRequestsList={response.data.data} />
          )}
        </Await>
      </React.Suspense>
      {/* Reveiws in progress section starts here */}
      <SubTitle
        icon={reviewsInProgessTitleIcon}
        title="reviews in progress"
        overrideStyle={classes.subTitle}
      />
      <React.Suspense>
        <Await
          resolve={data.inProgressEditRequests}
          errorElement={<DashboardMessageContainer status="error" />}
        >
          {({ response }) => (
            <SharedWithMeArticlesList reviewRequestsList={response.data.data} />
          )}
        </Await>
      </React.Suspense>
    </>
  );
}

const inProgressEditRequestsLoader = (sendRequest) => {
  const reqMethod = "get";
  const url = "/articles/review-requests/received/?status=inprogress";
  const data = {};

  const editRequestLoader = sendRequest(reqMethod, url, data).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });

  return editRequestLoader;
};

const pendingEditRequestsLoader = (sendRequest) => {
  const method = "get";
  const url = "/articles/review-requests/received/?status=pending";
  const data = {};

  const editRequestLoader = sendRequest(method, url, data).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });

  return editRequestLoader;
};

export const sharedWithMePageLoader = (sendRequest) => () => {
  const pendingEditRequests = pendingEditRequestsLoader(sendRequest);
  const inProgressEditRequests = inProgressEditRequestsLoader(sendRequest);

  return defer({ pendingEditRequests, inProgressEditRequests });
};
