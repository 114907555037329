import React, { useEffect, useRef, useState } from "react";
import classes from "./SuggestChangesToArticle.module.css";
import WriteArticleTitle from "../components/WriteArticleTitle";
import EditArticleEditor from "../components/EditArticleEditor";
import WriteArticleErrorMessage from "../components/WriteArticleErrorMessage";
import SuggestChangesArticleButtonSet from "../components/SuggestChangesArticleButtonSet";
import { useLoaderData, useActionData } from "react-router";
import { toast } from "react-toastify";
import {
  useSearchParams,
  useParams,
  useSubmit,
  useNavigate,
  defer,
} from "react-router-dom";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import RequestProgressBar from "../components/RequestProgressBar";
import displayToastErrors from "../utils/displayToastErrors";

export default function SuggestChangesToArticle() {
  const [title, setTitle] = useState("");
  // this ref (contentRef) is used to keep track of the content, a user types within the tinymce
  const contentRef = useRef(null);
  // this variable (articleContent) is used only to set the content initially inside the editor
  const [articleContent, setArticleContent] = useState(null);
  const [searchParams] = useSearchParams();
  const articleReviewRequestId = searchParams.get("reviewRequestId");
  const { id: articleId } = useParams();
  const articleLoader = useLoaderData();
  const actionData = useActionData();
  const submit = useSubmit();
  const navigate = useNavigate();
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);

  // errors
  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);

  // set Docuement title
  useDocumentTitle("Puplier | Suggest Changes To Article");

  // triggers to display existing values in relavent input fields(Triggers once the loader is finished executing)
  useEffect(() => {
    const { suggestChangesLoader } = articleLoader;

    suggestChangesLoader
      .then(({ response }) => {
        const articleDetails = response.data.data;

        setTitle(articleDetails.title);
        setArticleContent(articleDetails.content);
      })
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data.message,
          "Faild to load article data!"
        );
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });
  }, [articleLoader]);

  const onSubmit = (actionType) => {
    if (title && contentRef.current.getContent()) {
      let values = {
        title,
        content: contentRef.current.getContent(),
        reviewRequestId: articleReviewRequestId,
        actionType,
      };

      submit(values, {
        method: "post",
        action: `/article/${articleId}/suggest-changes?reviewRequestId=${articleReviewRequestId}`,
      });
    } else {
      // scroll the page to the top
      window.scrollTo(0, 0);

      toast.error("Please fix the errors before submitting");
      if (!title) {
        setTitleError(true);
      }
      if (!contentRef.current.getContent()) {
        setContentError(true);
      }
    }
  };

  // this useEffect triggers after the action method is executed
  useEffect(() => {
    if (actionData) {
      // this block triggers if the user had pressed the "save" button
      if (actionData.status === "ok") {
        toast.success(actionData.response.data.message);

        if (actionData.intent === "revoke-access") {
          navigate(`/dashboard/shared-with-me`);
        }
      } else {
        displayToastErrors(
          actionData?.error?.response?.data.message,
          "Faild to perfome the action!"
        );
      }
    }
  }, [actionData]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
      />
      <div className="container customContainer">
        <div className="row justify-content-center">
          <div className="col-xxl-auto col-xl-8 col-12">
            <div className={classes.editArticleContainer}>
              <form>
                <WriteArticleTitle
                  title={title}
                  setTitle={setTitle}
                  titleError={titleError}
                  setTitleError={setTitleError}
                />
                <EditArticleEditor
                  setContentError={setContentError}
                  content={articleContent}
                  contentRef={contentRef}
                />
                {contentError && (
                  <WriteArticleErrorMessage errorMessage="This field is required!" />
                )}
                <SuggestChangesArticleButtonSet onSubmit={onSubmit} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const suggestChangesLoader =
  (sendRequest) =>
  ({ request }) => {
    const reviewRequestId = new URL(request.url).searchParams.get(
      "reviewRequestId"
    );

    const suggestChangesLoader = sendRequest(
      "get",
      `/articles/review-requests/received/${reviewRequestId}/suggestion/`
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });

    return defer({ suggestChangesLoader });
  };

export const suggestChangesAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const { actionType, reviewRequestId, title, content } =
      Object.fromEntries(formData);

    let url = `/articles/review-requests/received/${reviewRequestId}/save_changes/`;
    let method = "put";

    if (actionType === "revoke-access") {
      url = `/articles/review-requests/received/${reviewRequestId}/save_changes/?revoke=true`;
    }

    let data = { title, content };
    let resolved = null;
    let rejected = null;

    if (actionType === "save") {
      resolved = (response) => {
        return {
          status: "ok",
          intent: "save",
          response,
        };
      };

      rejected = (error) => {
        return {
          status: "error",
          intent: "save",
          error,
        };
      };
    } else {
      resolved = (response) => {
        return {
          status: "ok",
          intent: "revoke-access",
          response,
        };
      };

      rejected = (error) => {
        return {
          status: "error",
          intent: "revoke-access",
          error,
        };
      };
    }

    return sendRequest(method, url, data, resolved, rejected);
  };
