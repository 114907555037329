import { useEffect, useState } from "react";
import useHttp from "./useHttp";
import displayToastErrors from "../utils/displayToastErrors";

export default function useFetchAllHighestQualifications() {
  const [qualificationsList, setQualificationsList] = useState([]);
  const { sendRequest } = useHttp();

  const getSimilarProfiles = () => {
    const method = "get";
    const url = "/highest-qualifications/";
    const params = null;

    const resolved = (response) => {
      let qualificationData = response.data.data.qualifications;
      qualificationData = qualificationData.map((data) => ({
        key: data.label,
        value: data.label,
      }));

      setQualificationsList(qualificationData);
    };

    const rejected = (error) => {
      displayToastErrors(
        error?.response?.data?.message,
        "Failed to load highest qualifications list!"
      );
    };

    sendRequest(method, url, params, resolved, rejected);
  };

  useEffect(() => {
    getSimilarProfiles();
  }, []);

  return { qualificationsList };
}
