import React, { useEffect } from "react";
import classes from "../pages/EditArticle.module.css";
import buttonIcon from "./images/mark-as-under-review-button.png";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import useHttp from "../custom-hooks/useHttp";
import { toast } from "react-toastify";
import displayToastErrors from "../utils/displayToastErrors";

function UnderReviewButtonSet({ articleDetails }) {
  const navigate = useNavigate();
  const {
    sendRequestAndTrackProgress: sendToAccepted,
    isLoading: toAcceptedIsLoading,
    actionData: toAcceptedActionData,
  } = useHttp();

  const {
    sendRequestAndTrackProgress: sendToDraft,
    isLoading: toDraftIsLoading,
    actionData: toDraftActionData,
  } = useHttp();

  const acceptArticle = () => {
    sendToAccepted(
      "put",
      `/articles/${articleDetails.id}/change-status/under-review/accepted`
    );
  };

  const markArticleAsDraft = () => {
    sendToDraft(
      "put",
      `/articles/${articleDetails.id}/change-status/under-review/draft`
    );
  };

  // triggers once a response is recieved to the under-review to accepted conversion request
  useEffect(() => {
    if (toAcceptedActionData) {
      if (toAcceptedActionData.status === "ok") {
        toast.success(toAcceptedActionData.response.data.message);
        navigate(`/article/${articleDetails.id}/edit`);
      } else {
        displayToastErrors(
          toAcceptedActionData?.error?.response?.data.message,
          "Faild to mark the article as accepted!"
        );
      }
    }
  }, [toAcceptedActionData]);

  // triggers once a response is recieved to the under-review to draft conversion request
  useEffect(() => {
    if (toDraftActionData) {
      if (toDraftActionData.status === "ok") {
        toast.success(toDraftActionData.response.data.message);
        navigate(`/article/${articleDetails.id}/edit`);
      } else {
        displayToastErrors(
          toDraftActionData?.error?.response?.data.message,
          "Faild to mark the article as draft!"
        );
      }
    }
  }, [toDraftActionData]);

  return (
    <div className={`row justify-content-center`}>
      <div
        className={`col-sm-auto col-12 d-sm-flex d-block ${classes.buttonsContainer}`}
      >
        <div className="row">
          <div className="col-sm-auto col-12">
            <button
              type="button"
              onClick={markArticleAsDraft}
              className={classes.saveDraftButton}
            >
              {toDraftIsLoading ? (
                <BeatLoader loading size={10} />
              ) : (
                "mark as draft"
              )}
            </button>
          </div>
          <div className="col-sm-auto col-12">
            <button
              type="button"
              onClick={acceptArticle}
              className={classes.markAsUnderReview}
            >
              {toAcceptedIsLoading ? (
                <BeatLoader loading size={10} />
              ) : (
                <>
                  <img
                    className={classes.markAsUnderReviewButtonIcon}
                    src={buttonIcon}
                    alt=""
                  />
                  Mark as Accepted
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderReviewButtonSet;
