import React, { useContext, useEffect, useState } from "react";
import classes from "./ArticleDetails.module.css";
import SingleCommentItem from "../components/SingleCommentItem";
import SingleReviewer from "../components/SingleReviewer";
import { NavLink, useLoaderData, useParams, defer } from "react-router-dom";
import PostArticleComment from "../components/PostArticleComment";
import ArticleDetailsSuggestChangesButton from "../components/ArticleDetailsSuggestChangesButton";
import { userContext } from "../components/LoggedInBaseLayout";
import defaultAvatar from "../components/images/avator.png";
import RequestProgressBar from "../components/RequestProgressBar";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import ArticleScoreContainer from "../components/ArticleScoreContainer";
import useFetchAllSimilarProfiles from "../custom-hooks/useFetchAllSimilarProfiles";
import ArticleDetailsInviteReviewersSection from "../components/ArticleDetailsInviteReviewersSection";
import ArticleDetailsSideBarTitle from "../components/ArticleDetailsSideBarTitle";
import InviteModal from "../components/InviteModal";
import displayToastErrors from "../utils/displayToastErrors";

export default function ArticleDetails() {
  const userDetails = useContext(userContext);
  const [isLoadingArticleDetails, setIsLoadingArticleDetails] = useState(true);
  const [isLoadingArticleScores, setIsLoadingArticleScores] = useState(true);
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [isLoadingArticleComments, setIsLoadingArticleComments] =
    useState(true);
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const [articleDetails, setArticleDetails] = useState(null);
  const [comments, setComments] = useState([]);
  const { id: articleId } = useParams();
  const [isReviewer, setIsReviewer] = useState(false);
  const user = useContext(userContext);
  const [reviewRequestDetails, setReviewRequestDetails] = useState(null);
  const { setDoucmentTitle } = useDocumentTitle(`Puplier`);
  const [articleScores, setArticleScores] = useState({
    accuracyScore: 0,
    completenessScore: 0,
    originalityScore: 0,
    consistencyScore: 0,
    timelinessScore: 0,
    totalScore: 0,
    totalVoteCount: 0,
  });
  const [reviewers, setReviewers] = useState([]);
  const articleLoader = useLoaderData();

  const {
    similarProfiles,
    success: fetchedSimilarProfiles,
    error: errorLoadingSimilarProfiles,
  } = useFetchAllSimilarProfiles();

  // this useEffect is responsible for setting the initial data to show in the article details page
  useEffect(() => {
    const { articleDetails, articleComments, articleScores } = articleLoader;

    articleDetails
      .then(({ response }) => {
        setArticleDetails(response.data.data);
        setReviewers(response.data.data.reviewers);
      })
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data?.message || "Faild to load the article!"
        );
      })
      .finally(() => {
        setIsLoadingArticleDetails(false);
      });

    articleComments
      .then(({ response }) => {
        setComments(response.data.data);
      })
      .catch(({ error }) => {
        if (error) {
          displayToastErrors(
            error?.response?.data?.message || "Faild to load article comments!"
          );
        }
      })
      .finally(() => {
        setIsLoadingArticleComments(false);
      });

    articleScores
      .then(({ response }) => {
        let scoreValues = response.data.data;
        setArticleScores({
          accuracyScore: scoreValues.accuracy,
          completenessScore: scoreValues.completeness,
          originalityScore: scoreValues.originality,
          consistencyScore: scoreValues.consistency,
          timelinessScore: scoreValues.timeliness,
          totalScore: scoreValues.total_score / 10,
          totalVoteCount: scoreValues.total_vote_count,
        });
      })
      .catch(({ error }) => {
        if (error) {
          // as this is not a major request in article details page,
          // we don't need to show an error message to the user
          // );
        }
      })
      .finally(() => {
        setIsLoadingArticleScores(false);
      });

    return () => {
      // Need to cancle theses API request on cleanup.
      // But with strictMode,this API requests are being aborted in very first render
      // So during the second render, the requests are already aborted and it throws an error.
      // It can be fixed by removing the strictMode from index.js
      // But as it's not a good practice to remove strictMode,
      // for now, I'm not aborting these requests.
    };
  }, [articleLoader]);

  // use to finish the progress bar
  useEffect(() => {
    if (
      !isLoadingArticleComments &&
      !isLoadingArticleDetails &&
      !isLoadingArticleScores
    ) {
      setCompleteProgressBar(true);
    }
  }, [
    isLoadingArticleComments,
    isLoadingArticleDetails,
    isLoadingArticleScores,
  ]);

  useEffect(() => {
    // set Docuement title
    if (articleDetails) {
      setDoucmentTitle(`${articleDetails?.title}`);
    }
  }, [articleDetails]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
        hideBackground={true}
      />
      <InviteModal
        show={inviteModalShow}
        onHide={() => setInviteModalShow(false)}
        articleDetails={articleDetails}
        similarProfiles={similarProfiles}
        fetchedSimilarProfiles={fetchedSimilarProfiles}
      />
      {articleDetails && (
        <div
          className={`container customContainer ${classes.artilceDetailsContainer}`}
          style={{ marginTop: "50px" }}
        >
          <div className="row">
            <div className={`col-xl col-12 ${classes.leftContainer}`}>
              {isReviewer ? (
                <ArticleDetailsSuggestChangesButton
                  reviewRequestDetails={reviewRequestDetails}
                  articleId={articleDetails.id}
                  userId={user?.userId || null}
                />
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-12">
                  {articleDetails.status.toLowerCase() === "published" && (
                    <p className={classes.publishedDate}>
                      {/* TODO: currently we show the article created data as the published date
                          as we don't have the article published date in the response.
                          We need to change this when the article published date is available in the response
                      */}
                      Published on {articleDetails.published_date}
                    </p>
                  )}
                  {articleDetails.status.toLowerCase() !== "published" ? (
                    articleDetails.is_author ? (
                      <p className={classes.articleStatus}>
                        status - {articleDetails.status}
                      </p>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <h1 className={classes.articleTitle}>
                    {articleDetails.title}
                  </h1>
                  <div
                    className={`d-sm-flex d-block justify-content-between ${classes.authorDetailsContainer}`}
                  >
                    <div className="d-flex">
                      <div className={classes.authorImageContainer}>
                        <NavLink
                          to={`/user/${articleDetails.author.username}/`}
                          className="text-decoration-none"
                        >
                          <div
                            className={classes.authorImage}
                            style={{
                              backgroundImage: articleDetails.author
                                .profile_picture
                                ? `url(${articleDetails.author.profile_picture})`
                                : `url(${defaultAvatar})`,
                            }}
                          ></div>
                        </NavLink>
                      </div>
                      <div
                        className={`d-flex align-items-center ${classes.authorNameContainer}`}
                      >
                        <NavLink
                          to={`/user/${articleDetails.author.username}/`}
                          className="text-decoration-none"
                        >
                          <p className={`m-auto ${classes.authorName}`}>
                            by {articleDetails.author.name}
                          </p>
                        </NavLink>
                      </div>
                    </div>
                    {/* this div is only visible in screens smaller than 1199px */}
                    {articleDetails.is_author ? (
                      <NavLink
                        to={`/article/${articleDetails.id}/edit`}
                        className="text-decoration-none"
                      >
                        <button
                          className={`d-xl-none d-block ${classes.editArticleButton}`}
                        >
                          edit article
                        </button>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`${classes.articleImage}`}
                    style={{
                      backgroundImage: `url(https://picsum.photos/1000?random=${articleDetails.id})`,
                    }}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: articleDetails.content,
                    }}
                    className={`${classes.descriptionContainer}`}
                  ></div>
                </div>
              </div>
            </div>
            <div className={`col-xl-auto col-12 ${classes.rightContainer}`}>
              {articleDetails.is_author ? (
                articleDetails.status.toLowerCase() !== "published" ? (
                  <NavLink
                    to={`/article/${articleDetails.id}/edit`}
                    className="text-decoration-none"
                  >
                    <button
                      className={`d-xl-block d-none ${classes.editArticleButton}`}
                    >
                      edit article
                    </button>
                  </NavLink>
                ) : null
              ) : null}
              {articleDetails.status.toLowerCase() === "published" ? (
                <ArticleScoreContainer
                  articleScores={articleScores}
                  setArticleScore={setArticleScores}
                  articleId={articleDetails.id}
                />
              ) : null}
              {reviewers.length > 0 && (
                <div className={`${classes.reviewersContainer}`}>
                  <ArticleDetailsSideBarTitle title="reviewers" />
                  <div
                    className={`row justify-content-xl-between justify-content-center ${classes.reviewersProfileContainer}`}
                  >
                    <div className="col-auto overflow-hidden">
                      <div className="row gx-xl-2">
                        {reviewers.map((reviewer, key) => (
                          <div className="w-auto" key={key}>
                            <SingleReviewer
                              userName={reviewer.username}
                              userImage={reviewer.profile_picture}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {fetchedSimilarProfiles ? (
                articleDetails.is_author ? (
                  articleDetails.status.toLowerCase() === "draft" ||
                  articleDetails.status.toLowerCase() === "under review" ||
                  articleDetails.status.toLowerCase() ===
                    "review inprogress" ? (
                    <ArticleDetailsInviteReviewersSection
                      similarProfiles={similarProfiles.slice(0, 5)}
                      articleDetails={articleDetails}
                      openInviteModal={() => setInviteModalShow(true)}
                    />
                  ) : null
                ) : null
              ) : null}
            </div>
          </div>
          <div className="row">
            <div
              className={`col-xl col-12 ${classes.leftContainer} ${classes.commentSection}`}
            >
              <PostArticleComment
                articleId={articleId}
                setComments={setComments}
              />
              <div>
                <p className={`${classes.commentCount}`}>
                  {comments.length} comments
                </p>
                {comments.map((comment, key) => (
                  <SingleCommentItem
                    key={key}
                    userImage={
                      comment.profile_picture
                        ? comment.profile_picture
                        : defaultAvatar
                    }
                    fullName={`${comment.author_name}`}
                    userName={comment.author_username}
                    comment={comment.comment}
                  />
                ))}
              </div>
            </div>
            <div
              className={`col-xl-auto d-xl-block d-none ${classes.rightContainer}`}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

const changeDateFormat = (date) => {
  const dateObj = new Date(date);
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return `${dateObj.getDate()} ${
    monthNames[dateObj.getMonth()]
  }, ${dateObj.getFullYear()}`;
};

const articleDetailsLoader =
  (sendRequest) =>
  ({ params: { id } }) => {
    const data = {};

    const resolved = (response) => {
      const publishedDate = changeDateFormat(response.data.data.published_date);
      const createdDate = changeDateFormat(response.data.data.created_date);

      // change the date format of the response
      response.data.data.created_date = createdDate;
      response.data.data.published_date = publishedDate;

      return { status: "ok", response };
    };

    const rejected = (error) => {
      return { status: "error", error };
    };

    return sendRequest(
      "get",
      `/articles/${id}/`,
      data,
      resolved,
      rejected
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

const articleCommentsLoader =
  (sendRequest) =>
  ({ params: { id: articleId } }) => {
    return sendRequest("get", `/articles/${articleId}/comments/`).then(
      (resp) => {
        if (resp.status === "error") {
          throw resp;
        } else {
          return resp;
        }
      }
    );
  };

const articleScoreLoader =
  (sendRequest) =>
  ({ params: { id: articleId } }) => {
    const params = {};

    return sendRequest("get", `/articles/${articleId}/votes/`, params).then(
      (resp) => {
        if (resp.status === "error") {
          throw resp;
        } else {
          return resp;
        }
      }
    );
  };

export const articleLoader = (sendRequest) => async (args) => {
  const articleDetails = articleDetailsLoader(sendRequest)(args);
  const articleComments = articleCommentsLoader(sendRequest)(args);
  const articleScores = articleScoreLoader(sendRequest)(args);

  return defer({
    articleDetails,
    articleComments,
    articleScores,
  });
};
