import { useCallback, useEffect, useState } from "react";
import useHttp from "./useHttp";
import { toast } from "react-toastify";
import displayToastErrors from "../utils/displayToastErrors";

export default function useFetchAllCategories() {
  /**
   * This custom hook is used to fetch all the categories available in the system
   * and returns those categories in two forms
   * 1. categories: this categories are categorized according to the initial character
   * 2. unSortedCategoriesArr: this list is just a list sorted alphabetically
   */

  const [categories, setCategories] = useState([]);
  const [unSortedCategoriesArr, setUnSortedCategoriesArr] = useState([]);
  const { sendRequest } = useHttp();

  const getAllTopics = useCallback(() => {
    const method = "get";
    const url = "/interests/";
    const resolved = (response) => {
      let topics = response.data.data.interests;
      let allCategories = [];

      // this method is used to sort the categories alphabetically
      // but the data inside is not categorized according to the starting character
      setUnSortedCategoriesArr(
        topics.sort((a, b) => a.label.localeCompare(b.label))
      );

      // categories the data according to the character it's starting with
      let ASCII_a = 97;
      for (let i = 0; i < 26; i++) {
        let char = String.fromCharCode(ASCII_a + i);
        let objArr = topics.filter((category) =>
          category.label.toLowerCase().startsWith(char)
        );
        let categories = objArr.map((category) => category.label);
        if (categories.length) {
          allCategories.push({ title: char, categories });
        }
      }

      setCategories(allCategories);
    };
    const rejected = (error) => {
      displayToastErrors(
        error.response.data.message,
        "Faild to load categories"
      );
    };

    sendRequest(method, url, null, resolved, rejected);
  }, []);

  useEffect(() => {
    getAllTopics();
  }, []);

  return { categories, unSortedCategoriesArr };
}
