import { useEffect, useState } from "react";
import useHttp from "./useHttp";
import displayToastErrors from "../utils/displayToastErrors";

export default function useFetchAllCountries() {
  const [countriesList, setCountriesList] = useState([]);
  const { sendRequest } = useHttp();

  const getSimilarProfiles = () => {
    const method = "get";
    const url = "/countries/";
    const params = null;

    const resolved = (response) => {
      let countryData = response.data.data.countries;
      countryData = countryData.map((data) => ({
        key: data.label,
        value: data.label,
      }));

      setCountriesList(countryData);
    };

    const rejected = (error) => {
      displayToastErrors(
        error?.response?.data?.message,
        "Failed to load countries list!"
      );
    };

    sendRequest(method, url, params, resolved, rejected);
  };

  useEffect(() => {
    getSimilarProfiles();
  }, []);

  return { countriesList };
}
