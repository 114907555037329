import { toast } from "react-toastify";

function displayToastErrors(
  errors,
  customErrorMessage = "Something went wrong. Please try again later."
) {
  if (typeof errors === "string") {
    // If errors is a string, show it in a toast notification
    toast.error(errors);
  } else if (Array.isArray(errors)) {
    // If errors is an array, show each error in a separate toast notification
    errors.forEach((error) => toast.error(error));
  } else {
    toast.error(customErrorMessage);
  }
}

export default displayToastErrors;
