import React, { useEffect, useState } from "react";
import classes from "./OnboardingCreateProfile.module.css";
import OnboardingCreateProfileInput from "../components/OnboardingCreateProfileInput.js";
import OnboardingCreateProfileSelect from "../components/OnboardingCreateProfileSelect.js";
import uploadImageIcon from "./images/add_photo.png";
import waveImg from "./images/wave.png";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import OnboardingCreateProfileTextarea from "../components/OnboardingCreateProfileTextarea";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import displayToastErrors from "../utils/displayToastErrors.js";
import useFetchAllCountries from "../custom-hooks/useFetchAllCountries";
import useFetchAllHighestQualifications from "../custom-hooks/useFetchAllHighestQualifications";

export default function OnboardingCreateProfile() {
  const { countriesList: countries } = useFetchAllCountries();
  const { qualificationsList: qualificationOptions } =
    useFetchAllHighestQualifications();
  const firstName = new URLSearchParams(window.location.search).get("fname");
  const submit = useSubmit();
  const navigate = useNavigate();
  const actionData = useActionData();
  const navigation = useNavigation();
  const [imgSrc, setImgSrc] = useState(null);

  // set Docuement title
  useDocumentTitle("Puplier | Onboarding | Create Profile");

  // yup url validation
  const yupUrlRegex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const validationSchema = Yup.object({
    username: Yup.string().required("This field is required!"),
    country: Yup.string().required("This field is required!"),
    currentPosition: Yup.string().required("This field is required!"),
    qualification: Yup.string().required("This field is required!"),
    description: Yup.string()
      .required("This field is required!")
      .max(1000, "You have exceeded the maximum character limit!"),
    website: Yup.string().matches(yupUrlRegex, "Invalid URL!"),
  });

  const initialValues = {
    username: "",
    country: "",
    currentPosition: "",
    company: "",
    qualification: "",
    website: "",
    description: "",
    profileImage: "",
  };

  const onSubmit = (values) => {
    // hide all toast notifications
    toast.dismiss();
    submit(values, { method: "post", action: "/signup/onboarding/profile" });
  };

  // triggers once the form is submitted
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        navigate("/signup/onboarding/interest");
      } else {
        displayToastErrors(
          actionData?.error?.response?.data.message,
          "Faild to create userprofile!"
        );
      }
    }
  }, [actionData]);

  const handleImageUpload = (files, rejectedFiles) => {
    // todo: validate the image and do the below after validation
    const currentFile = files[0];
    const imgFileReader = new FileReader();
    imgFileReader.addEventListener(
      "load",
      () => {
        setImgSrc(imgFileReader.result);
      },
      false
    );
    imgFileReader.readAsDataURL(currentFile);
  };

  return (
    <div className="container customContainer">
      <div className={`${classes.centerDiv}`}>
        <div className={classes.waveAndHello}>
          <div className={classes.waveImgDiv}>
            <img src={waveImg} alt="wave image" className={classes.waveImg} />
          </div>
          <p className={`${classes.helloText}`}>
            Hello {firstName ? firstName : "User"},
          </p>
        </div>
        <p className={`${classes.fewMoreStepsText}`}>
          Just a few more steps to complete your profile
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <Form>
                <div>
                  <OnboardingCreateProfileInput
                    type="text"
                    name="username"
                    label="Username"
                    required={true}
                  />
                </div>
                <div className={`row`}>
                  <div className={`col-12 col-md-6`}>
                    <OnboardingCreateProfileSelect
                      options={countries}
                      defaultValue="Select Country"
                      name="country"
                      label="Country"
                      required={true}
                    />
                  </div>
                  <div className={`col-12 col-md-6`}>
                    <OnboardingCreateProfileInput
                      type="text"
                      name="currentPosition"
                      label="Current Position"
                      required={true}
                    />
                  </div>
                </div>
                <div className={`row`}>
                  <div className={`col-12 col-md-6`}>
                    <OnboardingCreateProfileInput
                      type="text"
                      name="company"
                      label="Company Name"
                    />
                  </div>
                  <div className={`col-12 col-md-6`}>
                    <OnboardingCreateProfileSelect
                      options={qualificationOptions}
                      defaultValue="Select Qualification"
                      name="qualification"
                      label="Highest level of qualification"
                      required={true}
                    />
                  </div>
                </div>
                <div>
                  <OnboardingCreateProfileInput
                    type="text"
                    name="website"
                    label="Website"
                  />
                </div>
                <div>
                  <OnboardingCreateProfileTextarea
                    label="About You"
                    name="description"
                    required={true}
                  />
                </div>
                {imgSrc ? (
                  <div className={`${classes.uploadedImageParentContainer}`}>
                    <div className={`d-flex justify-content-center`}>
                      <img
                        className={`${classes.uploadedImage}`}
                        src={imgSrc}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={() => setImgSrc(null)}
                        className={`${classes.imageRemoveButton}`}
                      >
                        remove image
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* TODO: use below code once the image uploading feature is implemented */}
                    {/* <Dropzone
                      // todo: set maxSize
                      multiple={false}
                      accept="image/*"
                      onDrop={handleImageUpload}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            className={classes.uploadImageBox}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className={classes.uploadImageInnerBox}>
                              <div className={classes.uploadImageIcon}>
                                <img src={uploadImageIcon} alt="Upload Image" />
                              </div>
                              <p className={classes.uploadImageText}>
                                Upload Image
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone> */}
                    {/* TODO: remove below code once the dropzone is implemented */}
                    <section>
                      <div className={classes.uploadImageBox}>
                        <div className={classes.uploadImageInnerBox}>
                          <div className={classes.uploadImageIcon}>
                            <img src={uploadImageIcon} alt="Upload Image" />
                          </div>
                          <p className={classes.uploadImageText}>
                            Upload Image
                          </p>
                        </div>
                      </div>
                    </section>
                  </>
                )}
                <div className={classes.imageUploadBottomTextDiv}>
                  <p className={`${classes.imageUploadBottomText}`}>
                    We recommend uploading your real photo as it will help you
                    to be identified by your community
                  </p>
                </div>
                <button
                  type="button"
                  className={`${classes.submitButton}`}
                  onClick={() => {
                    // set all fields as touched. Otherwise formik will not validate the fields
                    formik.setTouched({
                      username: true,
                      country: true,
                      currentPosition: true,
                      qualification: true,
                      description: true,
                      website: true,
                    });

                    formik.validateForm().then((errors) => {
                      if (Object.keys(errors).length > 0) {
                        // scroll the page to the top
                        toast.error("Please fix the errors before submitting!");
                        window.scrollTo(0, 0);
                      } else {
                        formik.handleSubmit();
                      }
                    });
                  }}
                >
                  {navigation.state === "idle" ? (
                    <p className={classes.submitButtonText}>next</p>
                  ) : (
                    <BeatLoader loading size={10} />
                  )}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export const onboardingCreateProfileAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const {
      username,
      country,
      currentPosition,
      company,
      qualification,
      website,
      description,
      profileImage,
    } = Object.fromEntries(formData);

    const data = {
      username,
      country,
      job_title: currentPosition,
      company_name: company,
      highest_qualification: qualification,
      website,
      description,
      profile_picture: profileImage,
    };

    return sendRequest("post", "/user/info/", data);
  };
