import React, { useEffect, useState } from "react";
import WriteArticle from "../components/WriteArticle";
import { useLoaderData, useNavigate } from "react-router-dom";
import DraftButtonSet from "../components/DraftButtonSet";
import UnderReviewButtonSet from "../components/UnderReviewButtonSet";
import AcceptedButtonSet from "../components/AcceptedButtonSet";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import displayToastErrors from "../utils/displayToastErrors";

export default function EditArticle() {
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const articleLoader = useLoaderData();
  const [articleDetails, setArticleDetails] = useState(null);
  const [articleStatus, setArticleStatus] = useState(null);

  // set document title
  useDocumentTitle(`Puplier | Edit Article`);

  useEffect(() => {
    if (articleLoader.status === "ok") {
      if (user) {
        if (articleLoader.response.data.data.is_author) {
          setArticleDetails(articleLoader.response.data.data);
          setArticleStatus(
            articleLoader.response.data.data.status.toUpperCase()
          );

          // automatically scrolls the page to the top
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
          navigate(`/dashboard/my-articles/new`);
        }
      } else {
        navigate(`/`);
      }
    } else {
      displayToastErrors(
        articleLoader?.error?.response?.data?.message,
        "Failed to load article data!"
      );
    }
  }, [articleLoader]);

  return (
    <>
      <WriteArticle articleDetails={articleDetails} mode="editArticle">
        {articleStatus === "DRAFT" ? (
          <DraftButtonSet articleDetails={articleDetails} />
        ) : articleStatus === "UNDER REVIEW" ? (
          <UnderReviewButtonSet articleDetails={articleDetails} />
        ) : articleStatus === "ACCEPTED" ? (
          <AcceptedButtonSet articleDetails={articleDetails} />
        ) : (
          ""
        )}
      </WriteArticle>
    </>
  );
}

export const editArticleLoader =
  (sendRequest) =>
  ({ params: { id: articleId } }) => {
    return sendRequest("get", `/articles/${articleId}/`);
  };

export const editArticleAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const { title, category, content, thumbnail, articleId } =
      Object.fromEntries(formData);

    let data = {
      title,
      content,
      thumbnail,
      article_category: category,
    };

    return sendRequest("put", `/articles/${articleId}/`, data);
  };
