import useHttp from "./useHttp";

export default function useFindArticlesByUser(trackRequestProgress = false) {
  const { sendRequest, sendRequestAndTrackProgress, isLoading, actionData } =
    useHttp();

  const findArticlesByUser = (
    pageNumber = null,
    username = null,
    abortController = null
  ) => {
    const submitUrl = "/articles/";
    const data = {
      pageNumber,
      username,
    };

    const resolved = (response) => {
      response.data.data.isAuthor = response.data.data.is_owner;
      return { status: "ok", response };
    };
    const rejected = (error) => {
      return { status: "error", error };
    };

    if (trackRequestProgress) {
      // currently we do not need to track the progress of this request..
      // But we might want to do so in the future..
      // TODO: implement the functionality inside this block in the future if we need to.
      return null;
    }

    return sendRequest(
      "get",
      submitUrl,
      data,
      resolved,
      rejected,
      abortController
    );
  };
  return { findArticlesByUser, isLoading, actionData };
}
