import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import LoggedInNavigationBar from "./LoggedInNavigationBar";
import Footer from "./Footer";
import HideComponenet from "./HideComponenet";
import ShowComponentIn from "./ShowComponentIn";
import AlphaReleaseMessage from "./AlphaReleaseMessage";
import useHttp from "../custom-hooks/useHttp";

export const userContext = createContext();

const hideNavbarAndFooterIn = [
  "/signup/onboarding/profile",
  "/signup/onboarding/interest",
];

const showAlphaReleaseMessageIn = ["/"];

const LoggedInBaseLayout = () => {
  const [userDetails, setUserDetails] = useState(null);
  const { pathname } = useLocation();
  const { sendRequest } = useHttp();

  // automatically scrolls the page to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    const method = "get";
    const url = "/user/info/";
    const params = null;

    const resolved = (response) => {
      setUserDetails(response.data.data);
    };

    sendRequest(method, url, params, resolved);
  }, [pathname]);

  return (
    <userContext.Provider value={{ user: userDetails, setUserDetails }}>
      <ShowComponentIn in={showAlphaReleaseMessageIn}>
        <AlphaReleaseMessage />
      </ShowComponentIn>
      <HideComponenet in={hideNavbarAndFooterIn}>
        <LoggedInNavigationBar />
      </HideComponenet>
      <Outlet />
      <HideComponenet in={hideNavbarAndFooterIn}>
        <Footer />
      </HideComponenet>
    </userContext.Provider>
  );
};

export default LoggedInBaseLayout;
