import React, { useEffect, useState } from "react";
import DashboardTabTitle from "../components/dashboard/DashboardTabTitle";
import ReviewsInProgressList from "../components/dashboard/edit-requests-tab/ReviewsInProgressList";
import myArticlesIcon from "./images/my-articles.png";
import { defer, useLoaderData } from "react-router-dom";
import RequestProgressBar from "../components/RequestProgressBar";
import DashboardMessageContainer from "../components/DashboardMessageContainer";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import displayToastErrors from "../utils/displayToastErrors";

export default function EditRequests() {
  const reviewsLoader = useLoaderData();
  const [reviews, setReviews] = useState(null);
  const [faildedToLoadReviews, setFaildedToLoadReviews] = useState(false);
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);

  // set Docuement title
  useDocumentTitle("Puplier | Reviews In Progress");

  useEffect(() => {
    const { editRequestLoader } = reviewsLoader;

    editRequestLoader
      .then(({ response }) => {
        setReviews(
          response.data.data.filter(
            (review) =>
              review.status === "Review Inprogress" ||
              review.status === "Submitted to Review"
          )
        );
      })
      .catch(({ error }) => {
        if (error) {
          setFaildedToLoadReviews(true);
          displayToastErrors(
            error?.response?.data.message,
            "Faild to load reviews!"
          );
        }
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });

    return () => {
      // Need to cancle this API request on cleanup.
      // But with strictMode,this API request is being aborted in very first render
      // So during the second render, the request is already aborted and it throws an error.
      // It can be fixed by removing the strictMode from index.js
      // But as it's not a good practice to remove strictMode and also it's not a heavy API request,
      // for now, I'm not aborting this request.
      // abortController.abort();
    };
  }, [reviewsLoader]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
      />
      <DashboardTabTitle
        icon={myArticlesIcon}
        iconWidth="38px"
        iconHeight="38px"
        tabName="Reviews In Progress"
      />
      {reviews && <ReviewsInProgressList reviews={reviews} />}
      {/* show empty-listing message */}
      {reviews && !reviews.length && (
        <DashboardMessageContainer
          status="empty"
          message="No ongoing reviews!"
        />
      )}
      {/* show erro message */}
      {faildedToLoadReviews && <DashboardMessageContainer status="error" />}
    </>
  );
}

export const editRequestsLoader = (sendRequest) => () => {
  const editRequestLoader = sendRequest(
    "get",
    "/articles/review-requests/sent/"
  ).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });

  return defer({ editRequestLoader });
};
