import React, { useContext, useEffect, useRef } from "react";
import classes from "./PostArticleComment.module.css";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import useHttp from "../custom-hooks/useHttp";
import { userContext } from "./LoggedInBaseLayout";
import displayToastErrors from "../utils/displayToastErrors";

function PostArticleComment({ articleId, setComments }) {
  const commentRef = useRef();
  const { sendRequest, sendRequestAndTrackProgress, isLoading, actionData } =
    useHttp();
  const userDetails = useContext(userContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    // hide all toast notifications
    toast.dismiss();

    if (commentRef.current.value == "") {
      toast.error("Comment field is empty");
      return null;
    }

    const data = {
      content: commentRef.current.value,
    };

    sendRequestAndTrackProgress(
      "post",
      `/articles/${articleId}/comments/`,
      data
    );
  };

  // trggers after the comment is submitted
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(actionData.response.data.message);
        // clears the comment input filed
        commentRef.current.value = "";

        // fetch updated comments list
        const params = {};
        const resolved = (response) => {
          setComments(response.data.data);
        };
        const rejected = (error) => {
          displayToastErrors(
            error?.response?.data?.message || "Faild to load comments"
          );
        };

        sendRequest(
          "get",
          `/articles/${articleId}/comments/`,
          params,
          resolved,
          rejected
        );
      } else {
        displayToastErrors(
          actionData.error?.response?.data?.message || "Faild to submit comment"
        );
      }
    }
  }, [actionData]);

  return (
    <div className={`${classes.writeCommentSection}`}>
      <p className={`${classes.writecommentSectionTitle}`}>Write a comment</p>

      <form onSubmit={handleSubmit}>
        <textarea
          ref={commentRef}
          className={classes.newCommentInput}
          name="comment"
          id="comment"
          cols="30"
          rows="4"
          placeholder="Type something..."
        ></textarea>
        <div className="d-flex justify-content-end">
          <button type="submit" className={classes.commentSubmitButton}>
            {isLoading ? <BeatLoader loading size={10} /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default PostArticleComment;
