import React, { useState } from "react";
import SingleArticleItem from "../SingleArticleItem";
import Title from "./Title";
import titleIcon from "../images/popular-articles-title-icon.png";
import classes from "./PopularArticles.module.css";

const PopularArticles = () => {
  const [articlesList, setArticlesList] = useState([
    {
      id: 1,
      title:
        "Superconductivity at High Temperatures: Progress and Potential Applications",
      author: {
        username: "jennywilson",
        name: "Jenny Wilson",
      },
      thumbnail: "https://picsum.photos/id/90/500",
      score: "100",
      readTime: 2,
      saved: true,
    },
    {
      id: 1,
      title:
        "Nuclear Fusion: Progress, Challenges, and the Path to Sustainable Energy",
      author: {
        username: "Cody_Fisher",
        name: "Cody Fisher",
      },
      thumbnail: "https://picsum.photos/id/91/500",
      score: "90",
      readTime: 3,
      saved: false,
    },
    {
      id: 1,
      title:
        "Understanding Dark Matter Candidates and Their Implications for Cosmology",
      author: {
        username: "Robert_Fox",
        name: "Robert Fox",
      },
      thumbnail: "https://picsum.photos/id/92/500",
      score: "85",
      readTime: 2,
      saved: false,
    },
    {
      id: 1,
      title: "Gravitational Wave Detection and Astrophysical Insights",
      author: {
        username: "jennywilson",
        name: "Jenny Wilson",
      },
      thumbnail: "https://picsum.photos/id/93/500",
      score: "50",
      readTime: 4,
      saved: true,
    },
    {
      id: 1,
      title:
        "Superconductivity at High Temperatures: Progress and Potential Applications",
      author: {
        username: "jennywilson",
        name: "Jenny Wilson",
      },
      thumbnail: "https://picsum.photos/id/94/500",
      score: "100",
      readTime: 2,
      saved: true,
    },
    {
      id: 1,
      title:
        "Nuclear Fusion: Progress, Challenges, and the Path to Sustainable Energy",
      author: {
        username: "Cody_Fisher",
        name: "Cody Fisher",
      },
      thumbnail: "https://picsum.photos/id/95/500",
      score: "90",
      readTime: 3,
      saved: false,
    },
    {
      id: 1,
      title:
        "Understanding Dark Matter Candidates and Their Implications for Cosmology",
      author: {
        username: "Robert_Fox",
        name: "Robert Fox",
      },
      thumbnail: "https://picsum.photos/id/96/500",
      score: "85",
      readTime: 2,
      saved: false,
    },
    {
      id: 1,
      title: "Gravitational Wave Detection and Astrophysical Insights",
      author: {
        username: "jennywilson",
        name: "Jenny Wilson",
      },
      thumbnail: "https://picsum.photos/id/57/500",
      score: "50",
      readTime: 4,
      saved: true,
    },
  ]);

  return (
    <React.Fragment>
      <Title
        overrideStyles={classes.titleContainer}
        title="popular"
        icon={titleIcon}
      />
      <div className="row">
        {articlesList.map((article, key) => (
          <SingleArticleItem {...article} key={key} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default PopularArticles;
