import { useEffect } from "react";
import useHttp from "./useHttp";
import { useNavigate } from "react-router-dom";
import displayToastErrors from "../utils/displayToastErrors";

export default function useSuggestReview(reviewReqId, articleId) {
  const navigate = useNavigate();
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();

  const navigateToSuggestChangesPage = () => {
    navigate(
      `/article/${articleId}/suggest-changes?reviewRequestId=${reviewReqId}`
    );
  };

  const startReviewArticle = () => {
    sendRequestAndTrackProgress(
      "post",
      `/articles/review-requests/received/${reviewReqId}/start_reviewing/`
    );
  };

  // if the reviewer is authorized to edit article,navigate him to the suggest changes page
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        navigateToSuggestChangesPage();
      } else {
        displayToastErrors(
          actionData?.error?.response?.data.message,
          "Currently unable to edit the article!"
        );
      }
    }
  }, [actionData]);

  return { isLoading, startReviewArticle };
}
