import { useEffect, useState } from "react";
import useHttp from "./useHttp";
import displayToastErrors from "../utils/displayToastErrors";

export default function useUserDetailsLoader(username = null) {
  const [user, setUser] = useState(null);
  const { sendRequest } = useHttp();

  const getUserDetails = () => {
    const method = "get";
    const url = "/user/info/";
    const params = { username };

    const resolved = (response) => {
      setUser(response.data.data);
    };

    const rejected = (error) => {
      displayToastErrors(
        error?.response?.data?.message,
        "Failed to load user details!"
      );
    };

    sendRequest(method, url, params, resolved, rejected);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return { user };
}
