import useHttp from "./useHttp";

export default function useDeleteArticle(articleId) {
  let user = JSON.parse(localStorage.getItem("user"));
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();

  const deleteArticle = () => {
    const url = `/articles/${articleId}/`;
    const data = {};

    sendRequestAndTrackProgress("delete", url, data);
  };
  return { deleteArticle, actionData, isLoading };
}
