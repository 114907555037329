import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./ReviewsInProgressItem.module.css";

const ReviewsInProgressItem = ({ review, isReviewCompleted }) => {
  return (
    <div className={`row ${classes.singleReviewItemContainer}`}>
      <div className={`col-auto ${classes.imageContainerParent}`}>
        <NavLink to={`/article/${review.article.id}/${review.article.slug}`}>
          <div
            className={`${classes.imageContainer}`}
            style={{
              backgroundImage: `url(https://picsum.photos/200?random=${review.article.id})`,
            }}
          ></div>
        </NavLink>
      </div>
      <div className="col d-flex flex-column justify-content-center">
        <div>
          <NavLink
            to={`/article/${review.article.id}/${review.article.slug}`}
            className="text-decoration-none"
          >
            <p className={`${classes.articleTitle}`}>{review.article.title}</p>
          </NavLink>
          <div className="d-flex">
            <NavLink
              to={`/user/${review.reviewer_username}`}
              className="text-decoration-none"
            >
              <p className={`${classes.authorName}`}>
                {review.reviewer_username}
              </p>
            </NavLink>
          </div>
          <div className="d-flex justify-content-start mt-2">
            {/* visible only in screens smaller than 767px; */}
            <div className="d-md-none d-block">
              <div className="d-flex justify-content-start">
                {isReviewCompleted && (
                  <NavLink
                    to={`/review-request/${review.id}/view-suggestions?articleId=${review.article.id}`}
                  >
                    <button className={`${classes.actionButton}`}>
                      view suggestions
                    </button>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* visible only in screens greater than 767px; */}
      <div className="col-auto d-md-flex d-none flex-column justify-content-start">
        <div>
          <div className="d-flex justify-content-end">
            {isReviewCompleted && (
              <NavLink
                to={`/review-request/${review.id}/view-suggestions?articleId=${review.article.id}`}
              >
                <button className={`${classes.actionButton}`}>
                  view suggestions
                </button>
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsInProgressItem;
