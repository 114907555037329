import React from "react";
import ReviewsInProgressItem from "./ReviewsInProgressItem";
import classes from "./ReviewsInProgressList.module.css";

const ReviewsInProgressList = ({ reviews }) => {
  return (
    <div className={`row ${classes.ReviewsInProgressListContainer}`}>
      <div className="col-12">
        {/* currently we need to show only the review requests that are in "REVIEW_INPROGRESS" & "SUBMITTED_TO_REVIEW" statuses */}
        {reviews.map((review, key) => (
          <ReviewsInProgressItem
            isReviewCompleted={review.status === "Submitted to Review"}
            review={review}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};

export default ReviewsInProgressList;
