import React, { useState } from "react";
import SingleArticleItem from "../SingleArticleItem";

const TopArticleListContainer = () => {
  const [articlesList, setArticlesList] = useState([
    {
      id: 1,
      title:
        "Superconductivity at High Temperatures: Progress and Potential Applications",
      author: {
        username: "jennywilson",
        name: "Jenny Wilson",
      },
      thumbnail: "https://picsum.photos/200?random=55",
      score: "100%",
      readTime: 2,
      saved: true,
    },
    {
      id: 1,
      title:
        "Nuclear Fusion: Progress, Challenges, and the Path to Sustainable Energy",
      author: {
        username: "Cody_Fisher",
        name: "Cody Fisher",
      },
      thumbnail: "https://picsum.photos/200?random=56",
      score: "90%",
      readTime: 3,
      saved: false,
    },
    {
      id: 1,
      title:
        "Understanding Dark Matter Candidates and Their Implications for Cosmology",
      author: {
        username: "Robert_Fox",
        name: "Robert Fox",
      },
      thumbnail: "https://picsum.photos/200?random=57",
      score: "85%",
      readTime: 2,
      saved: false,
    },
    {
      id: 1,
      title: "Gravitational Wave Detection and Astrophysical Insights",
      author: {
        username: "jennywilson",
        name: "Jenny Wilson",
      },
      thumbnail: "https://picsum.photos/200?random=58",
      score: "50",
      readTime: 4,
      saved: true,
    },
  ]);

  return (
    <div className="row">
      {articlesList.map((article, key) => (
        <SingleArticleItem {...article} key={key} />
      ))}
    </div>
  );
};

export default TopArticleListContainer;
