import React, { useContext, useEffect, useState } from "react";
import titleIcon from "./images/edit-profile-title-icon.png";
import classes from "./EditProfileDetails.module.css";
import imageUploadIcon from "./images/edit-profile-image-upload-icon.png";
import EditProfileInputContainer from "../components/EditProfileInputContainer";
import EditProfileSelectContainer from "../components/EditProfileSelectContainer";
import { Formik, Form } from "formik";
import { useActionData, useNavigation, useSubmit } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import EditProfileTextAreaContainer from "../components/EditProfileTextAreaContainer";
import { BeatLoader } from "react-spinners";
import defaultAvatar from "../components/images/avator.png";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import RequestProgressBar from "../components/RequestProgressBar";
import useFetchAllCountries from "../custom-hooks/useFetchAllCountries";
import useFetchAllHighestQualifications from "../custom-hooks/useFetchAllHighestQualifications";
import displayToastErrors from "../utils/displayToastErrors";
import useUserDetailsLoader from "../custom-hooks/useUserDetailsLoader";
import { userContext } from "../components/LoggedInBaseLayout";

export default function EditProfileDetails() {
  const { setUserDetails } = useContext(userContext);
  const [profileImage, setProfileImage] = useState(defaultAvatar);
  const submit = useSubmit();
  const actionData = useActionData();
  const navigation = useNavigation();
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const { countriesList: countries } = useFetchAllCountries();
  const { qualificationsList: qualificationOptions } =
    useFetchAllHighestQualifications();
  const { user } = useUserDetailsLoader();

  // set Docuement title
  useDocumentTitle("Puplier | Edit Profile");

  const [initialValues, setInitialValues] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    currentPosition: "",
    company: "",
    highestQualification: "",
    website: "",
    description: "",
    profileImage: "",
  });

  // yup url validation
  const yupUrlRegex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const validationSchema = Yup.object({
    username: Yup.string().required("This field is required!"),
    country: Yup.string().required("This field is required!"),
    currentPosition: Yup.string().required("This field is required!"),
    highestQualification: Yup.string().required("This field is required!"),
    description: Yup.string()
      .required("This field is required!")
      .max(1000, "You have exceeded the maximum character limit!"),
    website: Yup.string().matches(yupUrlRegex, "Invalid URL!"),
  });

  // re-initialize the form values when the user data is available
  useEffect(() => {
    if (user) {
      const newInitialValues = {
        username: user?.username || "",
        firstName: user?.firstname || "",
        lastName: user?.lastname || "",
        email: user?.email || "",
        country: user?.country || "",
        currentPosition: user?.job_title || "",
        company: user?.company_name || "",
        highestQualification: user?.highest_qualification || "",
        website: user?.website || "",
        description: user?.description || "",
        profileImage: user?.profile_picture || "",
      };

      setInitialValues(newInitialValues);

      if (user.profile_picture) {
        setProfileImage(user.profile_picture);
      }
    }

    setCompleteProgressBar(true);
  }, [user]);

  const onSubmit = (values) => {
    // hide all toast notifications
    toast.dismiss();
    submit(values, { method: "post", action: "/profile/edit/details" });
  };

  // triggers once the response is recieved from the backend
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(
          actionData.response.data.message || "Profile updated successfully!"
        );
        // update the user context with the new user details
        setUserDetails(actionData.response.data.data);
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message,
          "Failed to update profile!"
        );
      }
    }
  }, [actionData]);

  return (
    <div className="col-12">
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
      />
      <div className="row justify-content-center">
        <div className={`col-xxl-auto col-xl-7 col-lg-8 col-12`}>
          <div className={`${classes.editProfileContainer}`}>
            <div className={`d-flex ${classes.titleContainer}`}>
              <div className={`d-flex ${classes.titleIconContainer}`}>
                <img
                  className={`m-auto ${classes.titleIcon}`}
                  src={titleIcon}
                  alt="icon"
                />
              </div>
              <div className="d-flex">
                <h1 className={`m-auto ${classes.title}`}>Edit Profile</h1>
              </div>
            </div>
            <div className={`${classes.profileImageContainer}`}>
              <div
                className={`${classes.profileImage}`}
                style={{
                  backgroundImage: `url(${profileImage})`,
                }}
              >
                <img
                  className={`${classes.imageUploadIcon}`}
                  src={imageUploadIcon}
                  alt="icon"
                />
              </div>
            </div>
            <div className={`${classes.formContainer}`}>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Form>
                      <div>
                        <EditProfileInputContainer
                          type="text"
                          name="username"
                          label="Username"
                          required={true}
                        />
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <EditProfileInputContainer
                            label="first name"
                            name="firstName"
                          />
                        </div>
                        <div className="col-sm-6 col-12">
                          <EditProfileInputContainer
                            label="last name"
                            name="lastName"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <EditProfileInputContainer
                            label="email"
                            name="email"
                            disabled={true}
                          />
                        </div>
                        <div className="col-sm-6 col-12">
                          <EditProfileSelectContainer
                            options={countries}
                            name="country"
                            label="country"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <EditProfileInputContainer
                            label="Current Position"
                            name="currentPosition"
                          />
                        </div>
                        <div className="col-sm-6 col-12">
                          <EditProfileSelectContainer
                            options={qualificationOptions}
                            name="highestQualification"
                            label="Highest level of qualification"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <EditProfileInputContainer
                            label="website"
                            name="website"
                          />
                        </div>
                        <div className="col-sm-6 col-12">
                          <EditProfileInputContainer
                            label="company name"
                            name="company"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className={classes.inputLabel}>about</p>
                          <EditProfileTextAreaContainer name="description" />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-auto col-12">
                          <button
                            type="button"
                            className={classes.formSubmitButton}
                            onClick={() => {
                              // set all the fields as touched. Otherwise formik will not validate the fields
                              formik.setTouched({
                                username: true,
                                country: true,
                                currentPosition: true,
                                experience: true,
                                description: true,
                                website: true,
                              });

                              formik.validateForm().then((errors) => {
                                if (Object.keys(errors).length > 0) {
                                  toast.error(
                                    "Please fix the errors before submitting!"
                                  );
                                  // scroll the page to the top
                                  window.scrollTo(0, 0);
                                } else {
                                  formik.handleSubmit();
                                }
                              });
                            }}
                          >
                            {navigation.state === "idle" ? (
                              "submit"
                            ) : (
                              <BeatLoader loading size={10} />
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const editProfileAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const {
      firstName,
      lastName,
      username,
      country,
      currentPosition,
      company,
      highestQualification,
      website,
      description,
      profileImage,
    } = Object.fromEntries(formData);

    const data = {
      username,
      country,
      job_title: currentPosition,
      company_name: company,
      highest_qualification: highestQualification,
      website,
      description,
      profile_picture: profileImage,
      firstname: firstName,
      lastname: lastName,
    };

    return sendRequest("put", "/user/info/", data);
  };
